<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section">
      <h4 class="menu-text">Work</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/workplans"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-layers"></i>
          <span class="menu-text">Work Plans</span>
        </a>
      </li>
    </router-link>
<!--
    <router-link
      to="/actionplans"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers"></i>
          <span class="menu-text">Action Plans</span>
        </a>
      </li>
    </router-link>
-->
    <router-link
      to="/taskmanager"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-grids"></i>
          <span class="menu-text">Task Manager</span>
        </a>
      </li>
    </router-link>


    <router-link
      to="/threats"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentFrameWorkData.show_risks == 1"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-warning"></i>
          <span class="menu-text">Risk Register</span>
        </a>
      </li>
    </router-link>


    <router-link
      to="/management/system"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentFrameWorkData.show_assets == 1"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers"></i>
          <span class="menu-text">Assets & Systems</span>
        </a>
      </li>
    </router-link>


    <router-link
      to="/statementofapplicability"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="currentFrameWorkData.show_statement == 1"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers"></i>
          <span class="menu-text">Statement of Applicability</span>
        </a>
      </li>
    </router-link>



    
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

  },
  computed: {
        ...mapGetters(["selectedFramework","currentFrameworks"]),  
    currentFrameWorkData() {

      
      var id = this.selectedFramework;
      var currentFramework = this.currentFrameworks.filter(
        function(o) {
          console.log(o,id);
          return o.id == id;
        }
      );

      if (currentFramework.length > 0) {
        return currentFramework[0];
      } else {
        return this.currentFrameworks[0];
      }

      
    }        
  }
};
</script>
