<template>
  <div
    class="subheader py-3 py-lg-8 subheader-transparent"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-baseline mr-5">
        <!--begin::Page Title-->
        <h2 class="subheader-title text-dark font-weight-bold my-2 mr-3">
          {{ title }}
        </h2>
        <!--end::Page Title-->
        <!--begin::Breadcrumb-->
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0"
        >
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li :key="`${i}-${breadcrumb.id}`" class="breadcrumb-item">
              <router-link
                :key="`${i}-${breadcrumb.id}`"
                v-if="breadcrumb.route"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span
                class="text-muted"
                :key="`${i}-${breadcrumb.id}`"
                v-if="!breadcrumb.route"
              >
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <div class="d-flex align-items-baseline mr-5">
        <table>
          <tr>
            <td class="headrSmallHeadtitle">Selected Framework</td><!--
            <td class="headrSmallHeadtitle">Selected System</td>
            <td class="headrSmallHeadtitle">Selected Ogranisation</td>-->
          </tr>
          <tr>
            <td>
              <b-form-select
                v-model="current_framework"
                :options="frameworkOptions"
              ></b-form-select>
            </td><!--
            <td>
              <b-form-select
                v-model="current_system"
                :options="systemOptions"
              ></b-form-select>
            </td>
            <td>
              <b-form-select
                v-model="current_organisation"
                :options="organisationOptions"
              ></b-form-select>
            </td> -->
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_CURRENT } from "@/core/services/store/auth.module";

export default {
  components: {},
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  data() {
    return {
      current_organisation: this.selectedOrganisation,
      current_system: this.selectedSystem,
      current_framework: this.selectedFramework,
    };
  },
  mounted() {
    this.current_organisation = this.selectedOrganisation;
    this.current_system = this.selectedSystem;
    this.current_framework = this.selectedFramework;
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "currentSettings",
      "currentOrganisations",
      "selectedOrganisation",
      "currentSystems",
      "selectedSystem",
      "currentFrameworks",
      "selectedFramework",
    ]),

    organisationOptions() {
      var results = [];
      console.log(this.currentOrganisations);
      this.currentOrganisations.forEach((item) => {
        results.push({
          value: item.id,
          text: item.name,
        });
      });
      return results;
    },
    systemOptions() {
      var results = [];
      this.currentSystems.forEach((item) => {
        results.push({
          value: item.id,
          text: item.name,
        });
      });
      return results;
    },
    frameworkOptions() {
      var results = [];
      this.currentFrameworks.forEach((item) => {
        results.push({
          value: item.id,
          text: item.name,
        });
      });
      return results;
    },

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }

        if (this.layoutConfig("subheader.clear")) {
          classes.push("mb-0");
        }
      }
      return classes.join(" ");
    },
  },
  watch: {
    current_organisation(val) {
      if (val != this.selectedOrganisation) {
        console.log("current_organisation", val);
        this.$store.dispatch(UPDATE_CURRENT, {
          type: "organisation",
          value: val,
        });
      }
    },
    current_system(val) {
      if (val != this.selectedSystem) {
        console.log("current_system", val);
        this.$store.dispatch(UPDATE_CURRENT, { type: "system", value: val });
      }
    },
    selectedFramework(val) {
      console.log(val);      
      this.current_framework = this.selectedFramework;
    },
    current_framework(val) {
      console.log(val);
      if (val != this.selectedFramework) {
        console.log("current_framework", val);
        this.$store.dispatch(UPDATE_CURRENT, { type: "framework", value: val });
      }
    },
  },
};
</script>
<style>
.headrSmallHeadtitle {
  font-size: 0.7em;
}
</style>
